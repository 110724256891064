<template>
  <!-- 调价 -->
  <div class="addPerson__wrap" v-show="visible">
    <el-header class="shadow page-header__wrap">
      <div class="flex">
        <div class="backBtn" @click="handleReturn">
          <el-image class="back-img" :src="require('@/assets/img/back.png')"></el-image>
          <span>返回</span>
        </div>
        <div class="title">
          <span class="site">当前位置：</span>
          {{ $route.meta.title }}
          <span> > </span>
          批量调价
        </div>
      </div>
    </el-header>

    <div class="main">
      <div class="mainBox" ref="mainBox" style="display: flex;flex-direction: column;">
        <el-form ref="elFormDom" inline :model="table.params" :size="subUnitSize">
          <!-- 选客户名称，实际要传分组id给接口 -->
          <el-form-item label="客户名称" prop="group_id">
            <el-select v-model="table.params.group_id" placeholder="请选择" filterable @change="getTable">
              <el-option
                v-for="item in merchantArr"
                :key="item.id"
                :label="item.name"
                :value="item.group_id">
              </el-option>
            </el-select>
          </el-form-item>
          <el-form-item label="商品分类" prop="cate_id">
            <el-select v-model="table.params.cate_id" placeholder="请选择" @change="getTable">
              <el-option
                v-for="item in cateOpt"
                :key="item.id"
                :label="item.name"
                :value="item.id">
              </el-option>
            </el-select>
          </el-form-item>
          <el-form-item>
            <el-button icon="el-icon-refresh-right" @click="clean">重置</el-button>
          </el-form-item>
        </el-form>
        <template v-if="!!table.params.group_id">
          <div>
            <!-- <el-button class="mtb10" type="primary" icon="el-icon-finished" :size="subUnitSize" @click="handlePrice">一键调价</el-button> -->
            <el-button class="mtb10" type="primary" icon="el-icon-finished" :size="subUnitSize" @click="handleBatch">批量保存</el-button>
          </div>
          <el-table
            :data="table.data"
            border
            style="width: 100%"
            :height="height"
            @selection-change="handleSelectionChange">
            <el-table-column
              type="selection"
              width="55">
            </el-table-column>
            <el-table-column
              prop="image"
              label="商品图片">
              <template slot-scope="{row}">
                <el-image
                  v-if="!!row.image"
                  style="width: 40px; height: 40px"
                  :src="row.image"
                  fit="cover"
                  :preview-src-list="[row.image]"></el-image>
                <el-image
                  v-else
                  style="width: 40px; height: 40px"
                  :src="require('@/assets/img/logo-icon.jpeg')"
                  fit="cover"
                  :preview-src-list="[require('@/assets/img/logo-icon.jpeg')]"></el-image>
              </template>
            </el-table-column>
            <el-table-column
              prop="good_name"
              label="商品名称"
              width="180">
            </el-table-column>
            <el-table-column
              prop="unit_name"
              label="单位">
            </el-table-column>
            <el-table-column
              prop="name"
              label="规格">
            </el-table-column>
            <el-table-column
              prop="cate_name"
              label="商品分类">
            </el-table-column>
            <el-table-column
              prop="object"
              label="参价对象"
              width="200px">
              <template slot-scope="{row}">
                <!-- 1:按商品分类 2:按商品名称,如果是按商品名称设置折扣率的，则参价对象和折扣率都允许修改 -->
                <span v-if="discount_type == 1">{{row.object}}</span>
                <el-input
                  v-else
                  v-model="row.object"
                  placeholder="参考价对象"
                  size="mini"
                >
                  <!-- <el-button slot="append" type="primary" icon="el-icon-check" circle @click="changePrice1(row)"></el-button> -->
                </el-input>
              </template>
            </el-table-column>
            <el-table-column
              prop="rate"
              label="折扣率"
              width="160px">
              <template slot-scope="{row,$index}">
                <span v-if="discount_type == 1">{{row.rate}}%</span>
                <el-input
                  v-else
                  v-model="row.rate"
                  placeholder="折扣率"
                  size="mini"
                  @input="countPrice(row,$index)"
                >
                  <span slot="suffix">%</span>
                </el-input>
              </template>
            </el-table-column>
            <el-table-column
              prop="spec_amount"
              label="参考价/元"
              width="170px">
              <template slot-scope="{row,$index}">
                <el-input
                  v-model="row.spec_amount"
                  placeholder="请输入参考价"
                  size="mini"
                  @input="countPrice(row,$index)"
                  >
                  <span slot="suffix">元</span>
                </el-input>
              </template>
            </el-table-column>
            <el-table-column
              prop="price"
              label="售价/元">
              <template slot-scope="{row}">
                <span v-if="!!row.price">¥ {{row.price}}</span>
                <span v-else></span>
              </template>
            </el-table-column>
            <el-table-column
              prop="action"
              label="操作">
              <template slot-scope="{row}">
                <el-button class="mtb10" type="primary" plain :size="subUnitSize" @click="changePrice(row)">保存</el-button>
              </template>
            </el-table-column>
          </el-table>
          <div class="pageWrap" v-if="table.total > 10">
            <el-pagination
              @size-change="handleSizeChange"
              @current-change="handleCurrentChange"
              :current-page="table.params.page"
              :page-sizes="[10, 20, 50, 100]"
              :page-size="table.params.count"
              layout="total, sizes, prev, pager, next, jumper"
              :total="table.total">
            </el-pagination>
          </div>
        </template>
        <div v-else style="height: 100px;line-height: 100px;font-size: 28px;font-weight: bold;text-align: center;margin-top: 100px;">
          请先选择客户名称查询！
        </div>
      </div>
    </div>
    <el-dialog
      width="400px"
      title="一键调价"
      :visible.sync="innerVisible"
      append-to-body
      center>
      <div>
        <div>在现有商品售价的基础上，可以设置上浮比例，若需要下浮可以填写负数</div>
        <div style="margin:20px 0;">现售价=原售价*（1+比例）</div>
        <el-form :model="form.data" :rules="form.rules" ref="elFormDom" label-width="70px" class="demo-ruleForm">
          <el-form-item prop="input" label="调价比例">
            <el-input v-model="form.data.rate" placeholder="请输入比例" id="input">
              <span slot="suffix">%</span>
            </el-input>
          </el-form-item>
        </el-form>
      </div>
      <div slot="footer" class="dialog-footer">
        <el-button type="primary" @click="submitForm('elFormDom')">确定</el-button>
      </div>
    </el-dialog>
  </div>
</template>

<script>
  import { getStorage } from '@/storage'
  import { mixinTable } from "@/mixins/table.js";
  export default {
    name: 'GoodsListPrice',
    props:['merchantArr','cateOpt'],
    mixins: [mixinTable],
    data() {
      return {
        token: getStorage('token'),
        subUnitSize: this.$store.state.subUnitSize, // 获取组件 size属性
        visible: false,
        isChange: false,
        height:0,
        table: {
          params:{
            page:1,
            count:10,
            status:'', // 1：在架商品, 2:下架商品
            cate_id: '',
            group_id: '',
            ids:'',
            filter_group_discount: 1,
          },
          data: [],
          total:0
        },
        
        form: {
          data: {
            rate:'',
          },
          rules:{

          }
        },
        multipleSelection: [],
        innerVisible: false,
        discount_type: 1, //折扣率：1:按商品分类 2:按商品名称
        point: 2,//售价小数点位 0:无小数 1:1位 2:2位 3:3位
        point_rule: 1,//小数规则 1:四舍五入 2:直接抹去
      }
    },
    mounted() {
      this.$nextTick(() => {
        this.height = this.$refs.mainBox.offsetHeight || 500
      })
    },
    methods: {
      getDetail() {
        this.getTable();
      },
      getTable() {
        if(!!this.table.params.group_id) {
          this.discount_type = this.merchantArr.filter(v => v.group_id == this.table.params.group_id)[0].discount_type; //折扣率：1:按商品分类 2:按商品名称
          this.point = this.merchantArr.filter(v => v.group_id == this.table.params.group_id)[0].point; //售价小数点位 0:无小数 1:1位 2:2位 3:3位
          this.point_rule = this.merchantArr.filter(v => v.group_id == this.table.params.group_id)[0].point_rule; //小数规则 1:四舍五入 2:直接抹去
        }
        this.$http
          .get("/admin/goods/specList", {params:this.table.params})
          .then((res) => {
            if (res.code === 1) {
              this.table.loading = false;
              this.table.data = res.data.list;
              this.table.total = res.data.total;
            }
          });
      },
      reset(done) {
        done && done()
      },
      toggle(show) {
        this.visible = show
        !show && this.reset()
      },
      handleSelectionChange(val) {
        this.multipleSelection = val;
      },
      // 计算价格
      countPrice(row,index) {
        const reg = /^[\d]+$/;
        if (row.rate && !reg.test(row.rate)) {
          row.rate = row.rate=row.rate.match(/\d+(\.\d{0,3})?/) ? row.rate.match(/\d+(\.\d{0,3})?/)[0] : '';
        }
        if (row.spec_amount && !reg.test(row.spec_amount)) {
          row.spec_amount = row.spec_amount=row.spec_amount.match(/\d+(\.\d{0,3})?/) ? row.spec_amount.match(/\d+(\.\d{0,3})?/)[0] : '';
        }
        // // 根据选择的客户判断客户id是否是新余或陆院客户（新余客户id：92和93,陆院客户id：94和96）
        // let merchant_id = this.merchantArr.filter(v => v.group_id == this.table.params.group_id)[0].id;
        // if(merchant_id == 92 || merchant_id == 93 || merchant_id == 94 || merchant_id == 96) {
        //   // 新余客户或陆院客户的价格是要折扣率*参考价，保留两位小数（超出位数就四舍五入）
        //   this.table.data[index].price = ((row.rate/100) * (row.spec_amount*100)/100).toFixed(2);
        // } else {
        //   // 计算出的价格保留两位小数，不四舍五入
        //   this.table.data[index].price = Math.floor(parseFloat((row.rate/100) * row.spec_amount)*100)/100;  
        // }

        // formatDecimal保留小数方法,参数1:金额总数，参数2：需保留的小数位数，参数3：(1:四舍五入，2：直接舍去)
        console.log(((row.rate*100)/100 * (row.spec_amount*100)/100)/100,"azy")
        let amount_total = ((row.rate*100)/100 * (row.spec_amount*100)/100)/100;
        this.table.data[index].price = this.formatDecimal(amount_total,this.point,this.point_rule)
        this.$set(this.table.data)
      },
      // 单个商品规格调价
      changePrice(row) {
        if(!!row.spec_amount) {
          this.priceFun(row)
        } else {
          this.$message.warning("请输入参考价")
        }
      },
      priceFun(row) {
        let _params = {
          id:row.id,
          price:row.spec_amount,
          group_id: this.table.params.group_id,
          object: row.object,
          rate: row.rate,
        }
        this.$http.post('/admin/goods/adjust', _params).then(res => {
          if(res.code == 1) {
            this.innerVisible = false
            this.getDetail();
            this.$message.success('操作成功！')
          }
        })
      },
      // 一键调价
      handlePrice() {
        if(this.multipleSelection.length > 0) {
          this.innerVisible = true;
        } else {
          this.$message.warning('请至少选择一条数据！')
        }
      },
      // 一键调价
      submitForm() {
        let _ids = this.multipleSelection.map((item) => {
          return item.id;
        }).join("|");
        let _params = {
          ids: _ids,
          rate: this.form.data.rate,
          group_id: this.table.params.group_id,
        }
        this.$http.post('/admin/goods/patch', _params).then(res => {
          if(res.code == 1) {
            this.innerVisible = false
            this.getDetail();
            this.$message.success('操作成功！')
            this.$refs.elFormDom.resetFields()
          }
        })
      },
      // 重置
      clean() {
        this.table.params.cate_id = '';
        this.table.params.page = 1;
        this.table.params.count = 10;
        this.getTable();
      },
      // 返回
      handleReturn() {
        this.$emit("refresh")
        this.toggle(false)
      },
      // 批量调价
      handleBatch() {
        if(this.multipleSelection.length > 0) {
          var arr = this.multipleSelection;
          let is_money = arr.some((item) => !item.spec_amount); // 检测提交的数据是否填了参考价
          let is_object = arr.some((item) => !item.object); // 检测提交的数据是否填了参考价对象
          let is_rate = arr.some((item) => !item.rate); // 检测提交的数据是否填了折扣率
          if(is_money == true) {
            this.$message.warning("请输入商品参考价")
            return
          }
          if(is_object == true) {
            this.$message.warning("请输入商品参考价对象")
            return
          }
          if(is_rate == true) {
            this.$message.warning("请输入商品折扣率")
            return
          }
          this.$confirm('请确认批量调价操作？','提示', {
            confirmButtonText: '确定',
            cancelButtonText: '取消',
            type: 'warning'
          }).then(() => {
            this.batchPriceFun();
          }).catch(() => {})
        } else {
          this.$message.warning("请至少选择一条数据")
          return
        }
      },
      batchPriceFun() {
        let _spec = this.multipleSelection.map(v => {
          return {
            id: v.id, //ID
            price: v.spec_amount, //参考价
            object: v.object, //参考对象
            rate: v.rate //折扣率
          }
        })
        let _params = {
          group_id: this.table.params.group_id,
          spec: _spec
        }
        this.$http.post('/admin/goods/patchAdjust',_params).then(res => {
          if(res.code == 1) {
            this.$message.success("操作成功")
            this.getTable();
          }
        })
      }
    }
  }
</script>

<style scoped lang="scss">
.addPerson__wrap {
  @include position($t: 0, $r: 0, $b: 0, $l: 0);
}
.pageWrap {
  text-align: right;
  padding: 10px 40px;
}
.mtb10 {
  margin: 10px 0;
}
.numWrapInput ::v-deep .el-input__suffix-inner {
  display: block;
  margin-top: 5px;
}
.numWrapInput ::v-deep .el-input-group__append {
  color: #2cb167 !important;
}
</style>