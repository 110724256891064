<template>

<!-- 新增 -->
  <div class="addPerson__wrap" v-show="visible">
    <el-header class="shadow page-header__wrap">
      <div class="flex">
        <div class="backBtn" @click="handleReturn">
          <el-image class="back-img" :src="require('@/assets/img/back.png')"></el-image>
          <span>返回</span>
        </div>
        <div class="title">
          <span class="site">当前位置：</span>
          {{ $route.meta.title }}
          <span> > </span>
          {{isChange == true ? '编辑商品' : '创建商品'}}
        </div>
      </div>
    </el-header>

    <div class="main" style="background:#f9f9f9;">
      <div class="mainBox" style="margin-bottom:0;max-width:1000px;">
        <el-form
          class="staff-from"
          v-loading="loading"
          element-loading-text="加载中…"
          ref='elFormDom'
          label-width='120px'
          label-position='right'
          :model='form.data'
          :rules='form.rules'
          :size="subUnitSize"
        >
          <div class="itemTime"><span class="btitle">基础信息</span></div>
          <el-row style="max-width:1000px;">
            <el-col :span="12">
              <el-form-item label="商品名称" prop="good_name">
                <el-input
                  v-model.trim="form.data.good_name"
                  placeholder="请输入"
                  clearable
                  maxlength="30"
                  id="good_name"
                  ></el-input>
              </el-form-item>
            </el-col>
            <el-col :span="12">
              <el-form-item label="标准名称" prop="norm_id">
                <el-select
                  v-model="form.data.norm_id"
                  filterable
                  remote
                  clearable
                  placeholder="请输入"
                  :remote-method="remoteMethod"
                  :loading="selLoading"
                  value-key="id"
                  style="width:100%;">
                  <el-option v-for="item in normArr" :label="item.name" :value="item.id" :key="item.id"></el-option>
                </el-select>
              </el-form-item>
            </el-col>
            <el-col :span="12">
              <el-form-item label="商品分类" prop="cate_name">
                <el-autocomplete
                  class="inline-input"
                  v-model="form.data.cate_name"
                  :fetch-suggestions="suggestionsCateFn"
                  placeholder="请输入"
                  @select="selectCateFn"
                  style="width:100%;"
                  id="cate_name"
                ></el-autocomplete>
              </el-form-item>
            </el-col>
            <el-col :span="12">
              <el-form-item label="下单分类" prop="order_cate_id">
                <el-cascader
                  v-model="form.data.order_cate_id"
                  filterable placeholder="请选择"
                  id="order_cate_id"
                  :options="orderCateArr"
                  :props="{ children: 'child',label: 'name',value:'id', checkStrictly: true }"
                  clearable
                  ></el-cascader>
              </el-form-item>
            </el-col>
            <!-- 默认是没有这四个字段的,只有商品分类是水果类(id:8)才有这四个字段 -->
            <template v-if="form.data.cate_id == 8">
              <el-col :span="12">
                <el-form-item label="毛重" prop="rough_weight">
                  <el-input v-model="form.data.rough_weight" clearable placeholder="请输入10个字以内" maxlength="10"></el-input>
                </el-form-item>
              </el-col>
              <el-col :span="12">
                <el-form-item label="毛重价" prop="rough_weight_price">
                  <el-input v-model="form.data.rough_weight_price" clearable placeholder="请输入10个字以内" maxlength="10"></el-input>
                </el-form-item>
              </el-col>
              <el-col :span="12">
                <el-form-item label="净重" prop="suttle">
                  <el-input v-model="form.data.suttle" clearable placeholder="请输入10个字以内" maxlength="10" ></el-input>
                </el-form-item>
              </el-col>
              <el-col :span="12">
                <el-form-item label="净重价" prop="suttle_price">
                  <el-input v-model="form.data.suttle_price" clearable placeholder="请输入10个字以内" maxlength="10"></el-input>
                </el-form-item>
              </el-col>
            </template>
            <el-col :span="12">
              <el-form-item label="排序" prop="sort">
                <el-input
                  v-model.trim="form.data.sort"
                  placeholder="请输入"
                  clearable
                  @input="form.data.sort=form.data.sort.replace(/^(0+)|[^\d]+/g, '')"
                  id="sort"
                ></el-input>
              </el-form-item>
            </el-col>
            <el-col :span="12">
              <el-form-item label="产地" prop="place">
                <el-input
                  v-model.trim="form.data.place"
                  placeholder="请输入"
                  clearable
                  id="place"
                >
                </el-input>
              </el-form-item>
            </el-col>
            <el-col :span="12">
              <el-form-item label="保质期" prop="quality_days">
                <el-input
                  v-model.trim="form.data.quality_days"
                  placeholder="请输入"
                  clearable
                  id="quality_days"
                >
                  <span slot="suffix">天</span>
                </el-input>
              </el-form-item>
            </el-col>
            <el-col :span="12">
              <el-form-item label="供应商/采购员" prop="bind_ids">
                <el-select v-model="form.data.bind_ids" multiple filterable class="width100" id="bind_ids">
                  <el-option v-for="item in supplierArr" :label="item.name" :value="item.id" :key="item.id">
                    <span style="float: left">{{ item.name }}</span>
                    <span style="float: right; color: #8492a6; font-size: 13px">{{ item.type == 1 ? '供应商' : '采购员' }}</span>
                  </el-option>
                </el-select>
              </el-form-item>
            </el-col>
            <el-col :span="12">
              <el-form-item label="" prop="loss">
                <template slot="label">
                  损耗率
                  <el-tooltip content="损耗率可以在采购审核中计算额外增加损耗部分的需采购数量" placement="top" >
                    <i class="el-icon-warning" style="color:#e6a23c;"></i>
                  </el-tooltip>
                </template>
                <el-input v-model="form.data.loss" clearable  @input="form.data.loss=form.data.loss.match(/\d+(\.\d{0,2})?/) ? form.data.loss.match(/\d+(\.\d{0,2})?/)[0] : ''">
                  <span slot="suffix">%</span>
                </el-input>
              </el-form-item>
            </el-col>
            <el-col :span="12">
              <el-form-item label="售后标准" prop="after_sale">
                <el-input
                  v-model.trim="form.data.after_sale"
                  placeholder="请输入"
                  clearable
                  id="after_sale"
                >
                </el-input>
              </el-form-item>
            </el-col>
          </el-row>

          <div class="itemTime">单位设置 <span style="font-size:12px;color:#a8a8a8;">(每个规格对应一个价格，一个商品可以按照不同的计价单位设置多个单位，每个计价单位可以设置不同的规格)</span></div>
          <el-row style="max-width:1000px;">
            <el-col style="width:80px;">
              <span>&nbsp;</span>
            </el-col>
            <el-col :span="4">
              <div><span style="color:#F56C6C;margin-right:3px;"></span>计件单位</div>
            </el-col>
            <el-col :span="5">
              <div><span style="color:#F56C6C;margin-right:3px;"></span>规格</div>
            </el-col>
            <el-col :span="3">
              <div>限购</div>
            </el-col>
          </el-row>
        
          <el-row class="item-row" v-for="(specItem,specIndex) in form.data.spec" :key="specIndex" style="max-width:1000px;">
            <!-- 规格 -->
            <el-col :span="24">
              <el-row :gutter="5">
                <el-col style="width:80px;">
                  <el-button type="danger" plain icon="el-icon-minus" circle size="mini" v-if="form.data.spec.length != 1" @click="handleMinus(specIndex)"></el-button>
                  <el-button type="primary" plain icon="el-icon-plus" circle size="mini" @click="handleAdd"></el-button>
                </el-col>
                <el-col :span="4">
                  <el-select v-model="specItem.unit_name" size="mini" filterable @focus="getUnit">
                    <el-option v-for="item in unitArr" :label="item.name" :value="item.name" :key="item.id"></el-option>
                  </el-select>
                </el-col>
                <el-col :span="5">
                  <el-input
                    v-model.trim="specItem.name"
                    placeholder="数字文本均可,15字内"
                    size="mini"
                    maxlength="15"
                  ></el-input>
                </el-col>
                <el-col :span="4">
                  <el-input
                    v-model="specItem.is_limit"
                    placeholder="请输入"
                    size="mini"
                    @input="specItem.is_limit=specItem.is_limit.replace(/^(0+)|[^\d]+/g,'')"
                  ></el-input>
                </el-col>
                <el-col style="width:90px;">
                  <el-button type="primary" size="mini" @click="groupAdd(specIndex)">新建报价</el-button>
                </el-col>
                <el-col style="width:161px;">
                  <el-radio v-model="is_basic" :label="specIndex" v-removeAriaHidden style="margin-right:0">基础单位</el-radio>
                  <el-tooltip content="设置基础单位后，其他单位可以转化成该单位对应数量，便于库存转化" placement="top-end" >
                    <i class="el-icon-warning" style="padding:5px 15px;color:#e6a23c;"></i>
                  </el-tooltip>
                </el-col>
              </el-row>
              <el-row style="margin-top:10px" v-if="(specIndex != is_basic) && (specItem.unit_name != form.data.spec[is_basic].unit_name)">
                <el-col style="width:80px;"><span>&nbsp;</span></el-col>
                <el-col :span="5" style="display:flex;align-items: center;">
                  <span style="margin-right:5px;">= </span>
                  <el-input v-model="specItem.basic_scale" size="mini" @input="specItem.basic_scale=specItem.basic_scale.match(/\d+(\.\d{0,2})?/) ? specItem.basic_scale.match(/\d+(\.\d{0,2})?/)[0] : ''"></el-input>
                  <span style="width:60px;font-size:14px;margin-left:5px;">{{form.data.spec[is_basic].unit_name}}</span>
                </el-col>
              </el-row>
            </el-col>
            <!-- 规格下的分组 -->
            <el-col :span="24" v-if="specItem.group.length > 0" style="background: #fbfdff;padding: 5px 0;margin-top:5px; border:solid 1px #eaf7f0;">
              <div v-for="(groupItem,groupIndex) in specItem.group" :key="groupIndex">
                <el-row :gutter="5" class="item-row-group" v-if="groupItem.is_show != 0">
                  <el-col style="width:80px;">
                    <span>&nbsp;</span>
                  </el-col>
                  <el-col :span="4">
                    <!-- 选客户名称，实际要传分组id给接口 -->
                    <el-select v-model="groupItem.group_id" placeholder="请选择客户" size="mini" style="width:100%;" @change="changeGroup(specIndex,groupIndex,$event)">
                      <el-option
                        v-for="item in merchantArr"
                        :key="item.id"
                        :label="item.name"
                        :value="item.group_id">
                      </el-option>
                    </el-select>
                  </el-col>
                  <el-col :span="5" class="flex inputWrap">
                    <el-input
                      v-if="groupItem.discount_type ==1"
                      v-model="groupItem.object_text"
                      placeholder="--"
                      size="mini"
                      :disabled="true"
                    ></el-input>
                    <template v-else>
                      <el-input
                        v-model="groupItem.object"
                        placeholder="参考价对象"
                        size="mini"
                        :readonly="groupItem.discount_type == 1 ? true : false"
                      ></el-input>
                      <el-input
                        v-model="groupItem.rate"
                        placeholder="折扣率"
                        size="mini"
                        @blur="countPrice(specIndex,groupIndex,groupItem)"
                        @input="groupItem.rate=groupItem.rate.match(/\d+(\.\d{0,3})?/) ? groupItem.rate.match(/\d+(\.\d{0,3})?/)[0] : ''"
                        :readonly="groupItem.discount_type == 1 ? true : false"
                      ><span slot="suffix">%</span></el-input>
                    </template>
                  </el-col>
                  <el-col :span="4" class="inputWrap" >
                    <el-input
                      v-model.trim="groupItem.price"
                      placeholder="请输入参考价"
                      size="mini"
                      @blur="countPrice(specIndex,groupIndex,groupItem)"
                      @input="groupItem.price=groupItem.price.match(/\d+(\.\d{0,3})?/) ? groupItem.price.match(/\d+(\.\d{0,3})?/)[0] : ''">
                    ><span slot="suffix">元</span></el-input>
                  </el-col>
                  <el-col class="inputWrap" style="width:120px;">
                    <span style="font-size:14px;margin-top:5px;display:block;">售价¥ {{groupItem.sell_price || 0}}</span>
                  </el-col>
                  <el-col :span="1">
                    <el-button type="danger" plain icon="el-icon-delete" circle size="mini" @click="groupMinus(specIndex,groupIndex)"></el-button>
                  </el-col>
                  <el-col :span="3" class="flex">
                    <span :class="groupItem.sold_out == 1 ? 'sold_out redColor' : 'sold_out greenColor'" @click="handSoldout(specIndex,groupIndex,groupItem)">{{groupItem.sold_out == 1 ? '售罄' : '有货'}}</span>
                    <el-tooltip v-if="groupIndex == 0" content="新增客户报价默认显示有货，客户可正常下单购买；点击可修改为售罄，则该客户能查看商品但无法下单，请谨慎操作" placement="top-end" >
                      <i class="el-icon-warning" style="padding:5px 15px;color:#e6a23c;"></i>
                    </el-tooltip>
                  </el-col>
                </el-row>
              </div>
            </el-col>
          </el-row>

          <div class="itemTime mt10">上传图片 <span style="font-size:12px;color:#a8a8a8;">(视频时长15秒内，最多上传1个视频，图片尺寸：800*800px，3M以内，最多6张)</span></div>
          <el-row>
            <el-col style="width:170px;">
              <el-upload
                class="avatar-uploader-image"
                action=""
                :headers="{ token: token }"
                :show-file-list="false"
                :before-upload="handleBeforeUpload"
                :http-request="httpRequest"
                accept="video/mp3, video/mp4, video/ogg, video/flv, video/avi, video/wmv, video/rmvb, video/mov">
                <template v-if="form.data.video">
                  <video :src="form.data.video" controls height="80px" width="160px"></video>
                </template>
                <template v-else>
                  <i class="el-icon-plus avatar-uploader-icon"></i>
                  <div style="position: absolute;top: 55px;color: #999;left: 65px;">视频</div>
                </template>
              </el-upload>
              <span v-if="!!form.data.video" class="delVideo" @click="delVideo"><i class="el-icon-delete"></i>删除视频</span>
            </el-col>
            <el-col :span="18">
              <el-upload
                action="/api/common/qiniu/upload"
                :limit="6"
                list-type="picture-card"
                class="imgWrap"
                :headers="{ token: token }"
                :on-preview="handlePictureCardPreview"
                :on-success="handleAvatarSuccess2"
                :before-upload="beforeAvatarUpload"
                :on-remove="handleRemove2"
                :file-list="fileList"
                :on-exceed="handleExceed"
                accept="image/jpeg, image/png, image/gif"
              >
                <i class="el-icon-plus"></i>
                <div style="position: absolute;top: 20px;color: #999;left: 25px;">图片</div>
              </el-upload>
              <el-dialog :visible.sync="dialogVisible" append-to-body>
                <img width="100%" :src="dialogImageUrl" alt />
              </el-dialog>
            </el-col>
          </el-row>

          <div class="itemTime mt10">备注常用语设置</div>
          <el-select
            v-model="form.data.remark"
            multiple
            filterable
            allow-create
            default-first-option
            placeholder="请输入备注常用语"
            style="max-width:1000px;">
            <el-option
              v-for="item in remarkOpt"
              :key="item"
              :label="item"
              :value="item">
            </el-option>
          </el-select>
          <div style="margin-top:20px;" v-if="!!isChange">
            创建人：{{form.data.create_name}}
          </div>
        </el-form>
      </div>
      <div class="footerWrap">
        <el-button size="medium" @click="handleReturn">取消</el-button>
        <el-button type="primary" size="medium" :loading="form.loading" @click="confirm">保存</el-button>
      </div>
    </div>

  </div>
</template>

<script>
  import { getStorage } from '@/storage'
  import { mixinTable } from "@/mixins/table.js";

  export default {
    name: 'GoodsListEdit',
    props: ['supplierOpt','merchantArr'],
    mixins: [mixinTable],
    data() {
      return {
        token: getStorage('token'),
        subUnitSize: this.$store.state.subUnitSize, // 获取组件 size属性
        visible: false,
        isChange: false,
        loading: false,
        form: {
          loading: false,
          data: {
            good_name: "",
            norm_id: '',// 标准名称
            cate_id: 13,
            bind_ids: [],
            sort: 100,
            quality_days: "",
            pics: [], //商品主图
            env_img: '',
            remark: [],
            status: 1, //状态：1开启 2关闭
            order_cate_id: '',
            order_cate_tow: '', // 下单分类子类
            loss: '', //损耗率
            place: '',//产地
            after_sale: '48小时内质量问题包退',//售后标准
            rough_weight: '',//毛重
            rough_weight_price: '',//毛重价
            suttle: '', //净重
            suttle_price: '',//净重价
            video: '',
            spec: [{
              name: "", //规格名称
              price: "", //默认售价
              unit_name: '', //商品单位
              is_limit: "", //限购数量
              is_basic:0, //是否是基础单位 1:是 0:否
              basic_scale: '', //转换倍数
              group: [
              ],
            }],
          },
          rules: {
            cate_name: [{ required: true, message:'必填项', trigger: 'change'}],
            order_cate_id: [{ required: true, message:'必填项', trigger: 'change'}],
            good_name: [
              { required: true, message:'请输入商品名称', trigger: 'change'} 
            ],
            spec: [{ required: true, message:'必填项', trigger: 'change'}],
            is_limit: [{ required: true, message:'必填项', trigger: 'change'}],
            limit_number: [{ required: true, message:'必填项', trigger: 'change'}],
            env_img: [{ required: true, message:'必填项', trigger: 'change'}],
            after_sale: [{ required: true, message:'必填项', trigger: 'change'}],
            rough_weight: [{ required: true, message:'必填项', trigger: 'change'}],
            rough_weight_price: [{ required: true, message:'必填项', trigger: 'change'}],
            suttle: [{ required: true, message:'必填项', trigger: 'change'}],
            suttle_price: [{ required: true, message:'必填项', trigger: 'change'}],
          }
        },
        orderCateArr: [], //下单分类
        fileList: [],
        dialogImageUrl: [],
        dialogVisible: false,
        unitArr: [], // 计件单位
        remarkOpt: [],
        normArr: [],// 标准名称
        selLoading: false,
        visiblePopover: false,
        is_basic: 0, //是否是基础单位 1:是 0:否
        supplierArr: [],
        point: 2,//售价小数点位 0:无小数 1:1位 2:2位 3:3位
        point_rule: 1,//小数规则 1:四舍五入 2:直接抹去
      }
    },
    methods: {
      async getDetail(row) {
        this.getOrderCateSel(); // 下单分类
        this.supplierArr = this.supplierOpt;
        if(!!row.id) {
          this.isChange = true;
          //获取供应商/采购员 goods_id隐藏商品专供
          await this.$http.get('/admin/supplier/allList',{params:{goods_id:row.id, page:1, count:10000}}).then(res => {
            if(res.code == 1) {
              this.supplierArr = res.data.list;
            }
          })
          await this.$http.get('/admin/norm/list',{params:{page:1,count:10000}}).then(res => {
            if(res.code == 1) {
              this.normArr = res.data.list;
            }
          })
          await this.$http.post('/admin/goods/detail', { id: row.id}).then(res => {
            if(res.code == 1) {
              this.form.data = res.data;
              this.form.data.sort = res.data.sort == 0 ? 100 : res.data.sort; // 编辑时历史数据排序是0的时候，改默认值100
              this.form.data.norm_id = res.data.norm_id == 0 ? '' : res.data.norm_id; // 标名为0时赋空
              this.form.data.after_sale = res.data.after_sale == '' ? '48小时内质量问题包退' : res.data.after_sale;
              if(!!res.data.order_cate_tow && res.data.order_cate_tow != 0) {
                this.form.data.order_cate_id = [res.data.order_cate_id,res.data.order_cate_tow]; //下单分类
              } else {
                this.form.data.order_cate_id = [res.data.order_cate_id];
              }
              // this.form.data.spec = res.data.spec;

              if(res.data.spec && res.data.spec.length > 0) {
                this.form.data.spec = res.data.spec.map(v => {
                  return {
                    ...v,
                    basic_scale: v.basic_scale == 0 ? '' : v.basic_scale,
                    group:v.group.map(j => {
                      return {
                        ...j,
                        object_text: `${j.object} : ${j.rate} %`,
                        group_id: Number(j.group_id),
                      }
                    })
                  }
                });
                let _is_basic = res.data.spec.findIndex(item => item.is_basic == 1); // // 查找输出基础单位索引，如果没有找到则为-1
                this.is_basic = _is_basic == -1 ? 0 : _is_basic; //如果返回-1没有基础单位时，索引赋值为0默认第一个为基础单位
              } else {
                this.form.data.spec = [{
                  name: "", //规格名称
                  price: "", //默认售价
                  unit_name: '', //商品单位
                  is_limit: "", //限购数量
                  is_basic:'', //是否是基础单位 1:是 0:否
                  basic_scale: '', //转换倍数
                  group: [
                  ],
                }];
                this.is_basic = 0;
              }

              if(res.data.bind_ids != 0) {
                this.form.data.bind_ids = res.data.bind_ids.split(",").map(Number)
              } else {
                this.form.data.bind_ids = [];
              }

              let arr = []
              if(res.data.pics.length > 0 && !!res.data.pics[0]) {
                res.data.pics.forEach(i => {
                  let str = {
                    uid: '',
                    url: i
                  }
                  arr.push(str)
                })
              }
              this.fileList = arr;
              this.form.data.env_img = res.data.pics[0]
            }
          })
        }
      },
      // 获取下单分类
      getOrderCate(value) {
        // admin/goods/orderCate （弃用：之前接口根据商品分类名称查询下单分类）
        this.$http.get('/admin/mini_cate/list', {params:{page:1,count:1000,cate_id:value}}).then(res => {
          if(res.code === 1) {
            if(res.data.list.length == 1 && this.form.data.order_cate_id == '') { // 如果先选了下单分类再选商品分类不替换下单分类
              this.form.data.order_cate_id = [res.data.list[0].id];
            }
          }
        })
      },
      // 获取标准名称
      remoteMethod(query) {
        if(query !== '') {
          this.selLoading = true;
          this.$http.get('/admin/norm/list', { params:{ page: 1, count: 1000, keyword: query }}).then(res => {
            if(res.code === 1) {
              this.normArr = res.data.list;
            }
          }).finally(() => {
            this.selLoading = false;
          })
        } else {
          this.normArr = [];
        }
      },
      // 选择分组后根据分组id或分类id查参考价
      changeGroup(specIndex,groupIndex,value) {
        this.point = this.merchantArr.filter(v => v.group_id == value)[0].point; //售价小数点位 0:无小数 1:1位 2:2位 3:3位
        this.point_rule = this.merchantArr.filter(v => v.group_id == value)[0].point_rule; //小数规则 1:四舍五入 2:直接抹去
        this.$http.get('/admin/group/getDiscount',{params:{id:value,cate_id:this.form.data.cate_id}}).then(res => {
          if(res.code == 1) {
            console.log(res.data.list[0])
            if(res.data.list.length > 0) {
              let _obj = res.data.list[0];
              this.form.data.spec[specIndex].group[groupIndex].object_text = `${_obj.object} : ${_obj.rate}%`;
              this.form.data.spec[specIndex].group[groupIndex].object = _obj.object;
              this.form.data.spec[specIndex].group[groupIndex].rate = _obj.rate;
              // // 计算出的价格保留两位小数，不四色五人
              // this.form.data.spec[specIndex].group[groupIndex].sell_price = Math.floor(parseFloat((_obj.rate/100) * this.form.data.spec[specIndex].group[groupIndex].price)*100)/100;  
             
              // formatDecimal保留小数方法,参数1:金额总数，参数2：需保留的小数位数，参数3：(1:四舍五入，2：直接舍去)
              this.form.data.spec[specIndex].group[groupIndex].sell_price =  this.formatDecimal(((parseFloat((_obj.rate/100) * this.form.data.spec[specIndex].group[groupIndex].price)*100)/100),this.point,this.point_rule) 
              this.$set(this.form.data.spec)
            } else {
              this.form.data.spec[specIndex].group[groupIndex].object_text = '';
              this.form.data.spec[specIndex].group[groupIndex].object = '';
              this.form.data.spec[specIndex].group[groupIndex].rate = '';
            }
            // 根据选择的客户id过滤获取当前选择客户的折扣率类型 1:按商品分类 2:按商品名称（则需要手动输入参价对象和折扣率）
            this.form.data.spec[specIndex].group[groupIndex].discount_type = this.merchantArr.filter(v => v.group_id == value)[0].discount_type;
            this.form.data.spec[specIndex].group[groupIndex].merchant_id = this.merchantArr.filter(v => v.group_id == value)[0].id;// 客户id
            this.form.data.spec[specIndex].group[groupIndex].point = this.merchantArr.filter(v => v.group_id == value)[0].point;//售价小数点位 0:无小数 1:1位 2:2位 3:3位
            this.form.data.spec[specIndex].group[groupIndex].point_rule = this.merchantArr.filter(v => v.group_id == value)[0].point_rule; //小数规则 1:四舍五入 2:直接抹去
            this.$set(this.form.data.spec)
          }
        })
      },
      // 获取计件单位
      getUnit() {
        this.$http.get('/admin/unit/list', {params:{page:1,count:1000}}).then(res => {
          if(res.code === 1) {
            this.unitArr = res.data.list;
          }
        })
      },
      
      reset(done) {
        this.isChange = false
        this.$refs.elFormDom.resetFields()
        this.fileList = [];
        this.is_basic = 0;
        this.form.data = {
          good_name: "",
          norm_id: '',
          cate_id: 13,
          bind_ids: "",
          sort: 100,
          quality_days: "",
          pics: [], //商品主图
          env_img: '',
          status: 1, //状态：1开启 2关闭
          order_cate_id: '',
          loss: '', //损耗率
          place: '',//产地
          after_sale: '48小时内质量问题包退',//售后标准rough_weight: '',//毛重
          rough_weight_price: '',//毛重价
          suttle: '', //净重
          suttle_price: '',//净重价
          video: '',
          spec: [{
            name: "", //规格名称
            price: "", //默认售价
            unit_name: "", //商品单位
            is_limit: "", //限购数量
            is_basic:'', //是否是基础单位 1:是 0:否
            basic_scale: '', //转换倍数
            group: [
            ],
          }],
        }
        done && done()
      },
      toggle(show) {
        this.visible = show
        !show && this.reset()
      },
      // 提交
      confirm() {
        this.$refs.elFormDom.validate(valid => {
          if (valid) {
            if(this.form.data.sort > 127) {
              this.$message.warning("排序最大值127,请修改")
              return 
            }
            let _imgarr = []
            this.fileList.forEach(i => {
              _imgarr.push(i.url)
            })
            var _spec = this.form.data.spec.map((v,i) => {
              return {
                ...v,
                cate_id: this.form.data.cate_id, // 把规格中的分类id替换成外边的分类id（场景：修改分类）
                is_basic: i == this.is_basic ? 1 : 0,  //是否是基础单位 1:是 0:否
                basic_scale: i == this.is_basic ? '' : ((!!v.basic_scale && v.basic_scale != 0 && v.unit_name != this.form.data.spec[this.is_basic].unit_name) ? v.basic_scale : 0), //转换倍数(如果当前规格是基础单位则不需要值)
              }
            })

            // 判断选择的分组是否设置了折扣体系
            let str = _spec.map(v => v.group.some((item) => !item.object));
            if(str.includes(true)) {
              this.$message.warning("商品缺失折扣率，请检查")
              return
            }
            // 判断规格的计件单位和规格名称都没填的话，接口参数需要传空规格数组
            let is_exist = _spec.every(item => item.unit_name == '' && item.name == '')
            console.log(is_exist,"is_exist")
            if(is_exist) {
              _spec = []
            }
            let _params = {
              id: this.form.data.id,
              good_name: this.form.data.good_name,
              norm_id: this.form.data.norm_id,
              cate_id: this.form.data.cate_id,
              bind_ids: !!this.form.data.bind_ids ? this.form.data.bind_ids.join(",") : '',
              sort: this.form.data.sort,
              quality_days: this.form.data.quality_days,
              status: this.form.data.status,
              pics: _imgarr,
              remark: this.form.data.remark,
              loss: this.form.data.loss, // 损耗率
              place: this.form.data.place,//产地
              after_sale: this.form.data.after_sale,//售后标准
              rough_weight: this.form.data.rough_weight,//毛重
              rough_weight_price: this.form.data.rough_weight_price,//毛重价
              suttle: this.form.data.suttle, //净重
              suttle_price: this.form.data.suttle_price,//净重价
              video: this.form.data.video, // 视频
              spec: _spec,
            }
            if(this.form.data.order_cate_id.length == 1) {
              _params.order_cate_id = this.form.data.order_cate_id[0]
            } else if(this.form.data.order_cate_id.length == 2) {
              _params.order_cate_id = this.form.data.order_cate_id[0];
              _params.order_cate_tow = this.form.data.order_cate_id[1];
            }
            console.log(_params)
            let apiUrl = this.isChange ? '/admin/goods/edit' : '/admin/goods/create'
            this.form.loading = true;
            this.$http.post(apiUrl, _params).then(res => {
              if(res.code === 1) {
                this.toggle(false);
                this.$emit('refresh')
              }
            }).finally(() => {
              this.form.loading = false;
            })
          }
        })
      },
      // 图片上传之前
      beforeAvatarUpload(file) {
        const isLt10M = file.size / 1024 / 1024 < 10;
        if (!isLt10M) {
          this.$message.warning('上传图片大小不能超过 10MB!');
        }
        return isLt10M;
      },
      handleBeforeUpload(file) {
        return new Promise((resolve, reject) => {
          const reader = new FileReader();
          reader.onload = (e) => {
            const video = document.createElement('video');
            video.preload = 'metadata';
  
            video.onloadedmetadata = () => {
              window.URL.revokeObjectURL(video.src);
              const duration = video.duration;
              console.log(duration,"duration")
              if (duration > 15) { // 视频时长限制，例如15秒
                this.$message.warning('上传的视频时长不能超过15秒！');
                return
                reject();
              } else {
                // this.$message.success('视频时长获取成功：' + duration + ' 秒');
                resolve(file);
              }
            };
  
            video.onerror = () => {
              this.$message.error('视频文件读取出错！');
              reject();
            };
  
            video.src = e.target.result;
          };
          reader.onerror = () => {
            this.$message.error('文件读取出错！');
            reject();
          };
  
          reader.readAsDataURL(file);
        });
      },
      handlePictureCardPreview(file) {
        this.dialogImageUrl = file.url;
        this.dialogVisible = true;
      },
      // 图片上传成功
      handleAvatarSuccess2(res, file) {
        console.log(res.data)
        this.form.data.env_img = res.data.all_path_url;
        let strImg = {
          url: res.data.all_path_url,
          name: ''
        }
        this.fileList.push(strImg)
        this.$refs.elFormDom.clearValidate();
      },
      handleRemove2(file, fileList) {
        if(this.fileList.length > 0) {
          this.fileList.forEach((v, i) => {
            if(file.uid === v.uid) {
              this.fileList.splice(i,1)
            }
          })
        }
      },
      handleExceed(files, fileList) {
        this.$message.warning(`当前限制最多只能选择 6 个文件`);
      },
      // 选择分类
      selectCateFn(item) {
        if (item.value != "无数据") {
          this.form.data.cate_id = item.cate_id;
        } else {
          this.form.data.cate_name= "";
        }
        this.getOrderCate(item.cate_id)// 根据商品分类id查询对应的下单分类
      },
      
      // 加规格
      handleAdd() {
        if(this.form.data.spec.length < 6) {
          let str = {
            name: "", //规格名称
            price: "", //默认售价
            unit_name: "", //商品单位
            is_limit: "", //限购数量
            is_basic:0, //是否是基础单位 1:是 0:否
            basic_scale: '', //转换倍数
            group: [],
          }
          this.form.data.spec.push(str)
        }
      },

      // 减规格
      handleMinus(index) {  
        if(this.form.data.spec.length > 1) {
          this.form.data.spec.forEach((v,i) => {
            if(index == i) {
              this.form.data.spec.splice(index, 1)
              this.$set(this.form.data.spec)
              if(this.form.data.spec.length == this.is_basic) {
                this.is_basic = Number(this.is_basic) - 1
              }
            }
          })
        }
      },

      // 新增报价
      groupAdd(specIndex) {
        console.log(specIndex)
        let str = {
          merchant_id: '', // 客户id
          group_id:'',
          object: '',
          rate: '',
          price:'',
          sell_price: '',
          object_text: '',
          discount_type: 1,
          sold_out: 0, //是否售罄：1-售罄；0-有货
        }
        console.log(this.form.data.spec[specIndex])
        this.form.data.spec[specIndex].group.push(str)
      },
      // 减报价
      groupMinus(specIndex,groupIndex) {
        console.log(specIndex, groupIndex)
        if(this.form.data.spec[specIndex].group.length > 0) {
          this.form.data.spec[specIndex].group.forEach((v,i) => {
            if(groupIndex == i) {
              this.form.data.spec[specIndex].group.splice(groupIndex, 1)
            }
          })
        }
      },
      // 计算价格
      countPrice(specIndex,groupIndex,groupItem) {
        // // 根据选择的客户判断客户id是否是新余或陆院客户（新余客户id：92和93,陆院客户id：94和96）
        // if(groupItem.merchant_id == 92 || groupItem.merchant_id == 93 || groupItem.merchant_id == 94 || groupItem.merchant_id == 96) {
        //   // 新余客户或陆院客户的价格是要折扣率*参考价，保留两位小数（超出位数就四舍五入）
        //   this.form.data.spec[specIndex].group[groupIndex].sell_price = ((groupItem.rate/100) * (groupItem.price*100)/100).toFixed(2);  
        //   this.$set(this.form.data.spec)
        // } else {
        //   // 计算出的价格保留两位小数，不四舍五入
        //   this.form.data.spec[specIndex].group[groupIndex].sell_price = Math.floor(parseFloat((groupItem.rate/100) * groupItem.price)*100)/100;  
        //   this.$set(this.form.data.spec)
        // }

        // formatDecimal保留小数方法,参数1:金额总数，参数2：需保留的小数位数，参数3：(1:四舍五入，2：直接舍去)
        console.log(groupItem,"123")
        let amount_total = ((groupItem.rate*100)/100 * (groupItem.price*100)/100)/100; // 总金额
        this.form.data.spec[specIndex].group[groupIndex].sell_price = this.formatDecimal(amount_total,groupItem.point,groupItem.point_rule)
      },
      // 返回
      handleReturn() {
        this.$emit("refresh")
        this.toggle(false)
      },
      // 是否售罄
      handSoldout(specIndex,groupIndex,groupItem) {
        console.log(groupItem,"groupItem")
        this.form.data.spec[specIndex].group[groupIndex].sold_out = groupItem.sold_out == 1 ? 0 : 1;
        this.$set(this.form.data.spec)
      },
      // 上传视频
      httpRequest(file) {
        let formData = new FormData();
        formData.append('file', file.file);
        this.$http.post('/common/qiniu/uploadFile', {formData, type:'upload'}).then(res => {
          this.form.data.video = res.data.all_path_url;
          this.$refs.elFormDom.clearValidate();
        })
      },
      delVideo() {
        this.form.data.video = '';
      }
      
    }
  }
</script>
<style scoped lang="scss">
.addPerson__wrap {
  @include position($t: 0, $r: 0, $b: 0, $l: 0);
} 
.footerWrap {
  text-align: center;
  padding: 10px;
  box-shadow: 0 0 8px 0 rgb(232 237 250 / 60%), 0 2px 4px 0 rgb(232 237 250 / 50%);
  z-index: 99;
  background:#fff;
}
.mainBox {
  max-width: 1000px;
  margin: 0 auto !important;
  background: #fff;
  padding: 24px;
}
.itemTime {
  display: block;
  margin-bottom: 10px;
  height: 30px;
  line-height: 30px;
}
.itemTime::before {
  content: "";
  width: 5px;
  height: 15px;
  background: #2cb167;
  border-radius: 6px;
  display: inline-block;
  margin-right: 10px;
  position: relative;
  top: 2px;
}
.flex {
  display: flex;
}
.mt10 {
  margin-top: 10px;
}
.mb20 {
  margin-bottom: 20px;
}
.width200px {
  width: 200px;
}
.after-table {
  padding: 10px;
  font-size: 13px;
  color: #303030;
  text-align: right;
}


/* *************** */
.item-row {
  margin-top: 20px;
}
.item-row-group {
  margin-top: 5px;
}
.el-button--mini.is-circle {
  padding: 4px;
}
.width100 {
  width:100%;
}
.itemTime {
  display: block;
  margin-bottom: 10px;
  border-bottom: solid 1px #eee;
  height: 30px;
  line-height: 30px;
}
.itemTime::before {
  content: "";
  width: 5px;
  height: 15px;
  background: #2cb167;
  border-radius: 6px;
  display: inline-block;
  margin-right: 10px;
  position: relative;
  top: 2px;
}
.flex {
  display: flex;
  align-items: center;
}
.inputWrap ::v-deep .el-input__suffix-inner {
  display: block;
  margin-top: 7px;
}
.mt10 {
  margin-top: 10px;
}
.imgWrap ::v-deep .el-upload-list__item{
  width: 80px !important;
  height: 80px !important;
}
.btitle::before {
  content: "*";
  color: #F56C6C;
  margin-right: 4px;
}
.imgWrap ::v-deep .el-upload--picture-card {
  width: 80px !important;
  height: 80px !important;
  line-height: 80px !important;
  position: relative;
}
.sold_out {
  font-size: 14px;
  cursor: pointer;
  text-decoration: underline;
}
// 上传视频样式
.avatar-uploader-image .el-icon-plus {
  width: 160px;
  height: 80px;
  border: 1px dashed #c0ccda;
  border-radius: 6px;
}
.avatar-uploader-image .el-icon-plus:hover {
  border: 1px dashed #2cb167;
}
.avatar-uploader-image .avatar {
  width: 160px;
  height: 80px;
}
.avatar-uploader-image .avatar-uploader-icon{
  line-height: 80px !important;
  font-size: 28px;
  color: #8c939d;
  background-color: #fbfdff;
}
.delVideo {
  margin-left: 40px;
  cursor: pointer;
  color: #999;
  font-size: 14px;
}
.delVideo:hover {
  color: #2cb167;
}
</style>
